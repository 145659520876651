<template>
  <div>
    <h3 class="data__title" v-if="record">
      {{ record.title_en }}
    </h3>
    <div class="data__list">
      <!-- Taxonomy  -->
      <div class="data__single">
        <p class="title">UID</p>
        <ul>
          <li>{{ record.uid | upper }}</li>
        </ul>
      </div>
      <!-- End Taxonomy -->

      <div>
        <div class="data__single" v-for="tax_el in tax" :key="tax_el.id">
          <div>
            <p class="title">{{ tax_el.title_en }}</p>
          </div>
          <ul>
            <li v-for="term_el in term(tax_el.id)" :key="term_el.id">
              <div>
                {{ term_el.title_en }}
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- Taxonomy  -->
      <div class="data__single">
        <p class="title">{{ $t("single.single_dimensions") }}</p>
        <ul>
          <li>{{ record.dimensions }}</li>
        </ul>
      </div>
      <!-- End Taxonomy -->
      <!-- Taxonomy  -->
      <div class="data__single">
        <p class="title">{{ $t("single.single_year_of_release") }}</p>
        <ul>
          <li>
            {{ record.start_year }} -
            {{ record.end_year }}
          </li>
        </ul>
      </div>
      <!-- End Taxonomy -->
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "recordDescription",
  data() {
    return {
      lang: Cookies.get("HKGA-MAINSITE-LANGUAGE")
    };
  },
  props: {
    record: Object
  },
  filters: {
    upper(value) {
      if (!value) return "";
      return value.toUpperCase();
    }
  },
  computed: {
    // meta: function() {
    //   return this.record.meta;
    // },

    tax: function() {
      const taxList = this.record.term.map(function(item) {
        if (item.taxonomy) {
          return item.taxonomy;
        }
      });

      var result = taxList.reduce((unique, o) => {
        if (o.slug) {
          if (!unique.some(obj => obj.slug === o.slug && obj.id === o.id)) {
            unique.push(o);
          }
        }

        return unique;
      }, []);

      return result;
    }
  },

  methods: {
    term: function(tax) {
      let termList = this.record.term.filter(function(item) {
        return item.taxonomy_id == tax;
      });
      console.log(termList);
      return termList;
    }
  }
};
</script>

<style lang="scss" scoped>
.data {
  &__title {
    @include fontfamily(GraLight);
    font-size: $fs-r-xl;
  }
  &__list {
    @include fontfamily(GraLight);
    margin-top: 50px;
  }
  &__single {
    ul {
      padding: 0;
      li {
        font-size: $fs-s;
        font-weight: 600;
      }
    }
    .title {
      font-size: $fs-xs;
    }
  }
}
</style>
